<template>
  <v-dialog v-model="buttonsDialog.show">
    <v-card>
      <v-card-title class="text-h5">
        {{ buttonsDialog.title }}
      </v-card-title>

      <v-card-text>
        {{ buttonsDialog.body }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn
          color="red darken-1"
          text
          @click="handleHide"
        >
          إلغاء
        </v-btn>

        <v-btn
          :color="buttonsDialog.saveButton.color || 'green'"
          :class="buttonsDialog.saveButton.class || ''"
          text
          @click="handleSubmit"
        >
          <v-icon v-if="buttonsDialog.saveButton.icon !== ''">
            {{ buttonsDialog.saveButton.icon }}
          </v-icon>
          {{ buttonsDialog.saveButton.text || 'تأكيد' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    name: 'CusotmDialog',
    data: (vm) => ({
      buttonsDialog: {
      },
    }),
    computed: mapState(['dialog']),
    watch: {
      dialog: {
        handler (o, n) {
          this.buttonsDialog = o
        },
        deep: true,
      },
    },
    methods: {
      handleHide () {
        this.$store.dispatch('changeStatus', false)
      },
      handleSubmit () {
        this.$store.dispatch('fireAction')
        this.$store.dispatch('changeStatus', false)
      },
    },
  }
</script>
